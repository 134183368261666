export const colors = {
	blue: '#0002e6',
	red: '#E70000',
	redBackground: '#FCF4F4',
	green: '#73C12A',
	greenBackground: '#F9FCF4'
};

export const fonts = {
	'suisse-intl': 'SuisseIntl, Helvetica, sans-serif',
	'tiempos-headline': 'TiemposHeadline, serif'
};
