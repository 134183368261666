import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const NavigationAnnouncer: React.FC = () => {
	const [message, setMessage] = useState('');
	const location = useLocation();

	useEffect(() => {
		const heading = document.querySelector('h1');
		setTimeout(() => setMessage(heading?.innerText || ''), 500);
	}, [location]);

	return (
		<span className="visually-hidden" role="status" aria-live="polite" aria-atomic="true">
			{message}
		</span>
	);
};

export default NavigationAnnouncer;
