import { useCallback, useState } from 'react';
import type { ChangeEventHandler } from 'react';
import { Link } from 'react-router-dom';

import Button from './Button';
import { routerPath } from './constants';
import { formStyles, linkStyles, messageStyles } from './InstructorSignIn';
import PageWrapper from './PageWrapper';
import ResetEmailSent from './ResetEmailSent';
import useResetPasswordForm from './useResetPasswordForm';

const InstructorResetPassword: React.VFC = () => {
	const [email, setEmail] = useState('');
	const { handleFormSubmit, isSubmitted } = useResetPasswordForm();

	const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
		setEmail(e.target.value);
	}, []);

	return isSubmitted ? (
		<ResetEmailSent email={email} backLink={routerPath.instructorSignIn} />
	) : (
		<PageWrapper pageTitle="Set or reset your password">
			<div css={messageStyles}>We’ll send you an email with instructions.</div>
			<form onSubmit={handleFormSubmit} css={formStyles}>
				<label htmlFor="email">Email address</label>
				<input
					id="email"
					name="email"
					type="email"
					required
					placeholder="Email Address"
					value={email}
					onChange={handleChange}
					autoComplete="username"
				/>

				<div className="form-footer">
					<div className="links-block">
						<Link to={routerPath.instructorSignIn} css={linkStyles}>
							Or go back to sign-in
						</Link>
					</div>
					<Button type="submit">Send</Button>
				</div>
			</form>
		</PageWrapper>
	);
};

export default InstructorResetPassword;
