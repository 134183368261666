import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { axiosConfig } from '~/api/axiosConfig';

import { routerPath } from './constants';
import { WebtextSignInContext } from './context';
import EnterNewPassword from './EnterNewPassword';
import InstructorResetPassword from './InstructorResetPassword';
import InstructorSignIn from './InstructorSignIn';
import NavigationAnnouncer from './NavigationAnnouncer';
import PasswordResetConfirmation from './PasswordResetConfirmation';
import StudentForgotPassword from './StudentForgotPassword';
import StudentSignIn from './StudentSignIn';
import WelcomePage from './WelcomePage';

axiosConfig();

interface Props {
	marketingHost: string;
}

const WebtextSignInApp: React.FC<Props> = ({ marketingHost }) => {
	return (
		<WebtextSignInContext.Provider value={{ marketingHost }}>
			<Router>
				<NavigationAnnouncer />
				<Routes>
					<Route path={routerPath.signInRoleSelection} element={<WelcomePage />} />
					<Route path={routerPath.studentSignIn} element={<StudentSignIn />} />
					<Route path={routerPath.studentForgotPassword} element={<StudentForgotPassword />} />
					<Route path={routerPath.instructorSignIn} element={<InstructorSignIn />} />
					<Route path={routerPath.instructorResetPassword} element={<InstructorResetPassword />} />
					<Route path={routerPath.enterNewPassword} element={<EnterNewPassword />} />
					<Route
						path={routerPath.passwordResetConfirmation}
						element={<PasswordResetConfirmation />}
					/>
				</Routes>
			</Router>
		</WebtextSignInContext.Provider>
	);
};

export default WebtextSignInApp;
