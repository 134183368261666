export const routerPath = {
	signInRoleSelection: '/signin',
	studentSignIn: '/signin/student',
	studentForgotPassword: '/signin/forgot_password',
	instructorSignIn: '/signin/instructor',
	instructorResetPassword: '/signin/reset_password',
	forgotPassword: '/signin/forgot_password',
	enterNewPassword: '/signin/enter_new_password/:token?',
	passwordResetConfirmation: '/signin/reset_confirmation'
};

export const endpoints = {
	signin: '/signin',
	passwordResets: '/password_resets'
};
