import { useContext } from 'react';

import { css } from '@emotion/core';

import SoomoLogo from '@soomo/lib/components/SoomoLogo/SoomoLogo';
import { breakpoints } from '@soomo/lib/styles/themes';
import { fonts } from '~/themes/signin';

import Alert from './Alert';
import { WebtextSignInContext } from './context';

interface Props {
	pageTitle: string;
	children: React.ReactNode;
	className?: string;
	alertMessage?: string;
}

const PageWrapper: React.FC<Props> = (props) => {
	const { pageTitle, children, className, alertMessage } = props;

	const { marketingHost } = useContext(WebtextSignInContext);

	return (
		<div css={styles(alertMessage)} className={className}>
			<header className="header">
				<a
					href={`https://${marketingHost}`}
					target="_blank"
					rel="noreferrer"
					title="Soomo Learning">
					<SoomoLogo height={34} width={166} role="img" aria-hidden="true" />
				</a>
				<a
					href={`https://${marketingHost}/support`}
					target="_blank"
					rel="noreferrer"
					title="Soomo Learning Support"
					className="contact-support">
					Contact Support
				</a>
			</header>

			<main className="main">
				{alertMessage && (
					<Alert severity="success" className="alert">
						{alertMessage}
					</Alert>
				)}
				<h1 className="page-title">{pageTitle}</h1>
				<div className="page-content">{children}</div>
			</main>

			<footer className="footer"></footer>
		</div>
	);
};

export default PageWrapper;

const styles = (alertMessage) => css`
	display: flex;
	flex-direction: column;
	font-family: ${fonts['suisse-intl']};

	.header {
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 2em;
	}

	.contact-support {
		color: #737171;
		font-size: 15px;
		text-decoration: none;
	}

	.main {
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 0 2em;
	}

	.footer {
		padding: 0 2em;
		min-height: 100px;
	}

	.alert {
		margin-top: 50px;
	}

	.page-title {
		font-size: 60px;
		font-weight: 700;
		font-family: ${fonts['tiempos-headline']};
		margin-top: 100px;
		text-align: center;

		${alertMessage &&
		css`
			margin-top: 50px;
		`}
	}

	.page-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	@media (max-width: ${breakpoints.small}) {
		.page-title {
			font-size: 40px;
			margin-top: 50px;
			line-height: 40px;
		}

		.header,
		.main,
		.footer {
			padding: 0 1em;
		}
	}
`;
