import { WebtextSignInContext } from './context';
import { messageStyles } from './InstructorSignIn';
import PageWrapper from './PageWrapper';

interface Props {
	signinUrl: string;
	signoutUrl: string;
	mixedRegistration: boolean;
	marketingHost: string;
}

const SignoutPage: React.FC<Props> = (props) => {
	const { signinUrl, signoutUrl, mixedRegistration, marketingHost } = props;

	const pageTitle = 'Thanks for using Soomo!';

	return (
		<WebtextSignInContext.Provider value={{ marketingHost }}>
			<PageWrapper pageTitle={pageTitle}>
				<div css={messageStyles}>
					You have successfully signed out. You may now close this tab/window.
				</div>

				<div css={messageStyles}>
					It looks like you {mixedRegistration ? 'may ' : ' '}access Soomo through your school’s
					learning management system. When you’re ready to continue working, please return there and
					click the link you normally use to access your webtext.
				</div>

				<div css={messageStyles}>
					For your convenience, this link will take you to your school’s sign-in page:
					<a href={signoutUrl} target="_blank" rel="noreferrer">
						{signoutUrl}
					</a>
				</div>

				{mixedRegistration && (
					<div css={messageStyles}>
						It also looks like you may sometimes access Soomo directly. If so, go to{' '}
						<a href={signinUrl} target="_blank" rel="noreferrer">
							{signinUrl}
						</a>{' '}
						to sign in with your email address and password.
					</div>
				)}
			</PageWrapper>
		</WebtextSignInContext.Provider>
	);
};

export default SignoutPage;
